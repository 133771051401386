import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { Row, Col, Container } from 'react-bootstrap'

class Footer extends React.Component{

    render() {
        const FooterMain = styled.div`
            background-color: #111;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
        `

        const SocialIcons = styled.div`
            .social_icon {
                font-size: 15px;
                color: #555;
                margin: 0 5px;
                cursor: pointer;
                transition: .5s;
                &:hover {
                    color: #24e5af;
                }
            }
        `
        return (
            <FooterMain>
                <Row style={{width:'70%', alignItems:'center'}} md={12}>
                <Col md={3} style={{color: '#fff', fontFamily: 'Teko'}}>
                <SocialIcons>
                    {/* <FontAwesomeIcon icon={faYoutube} className="social_icon" onClick={() => window.open('https://www.youtube.com')} /> */}
                    <FontAwesomeIcon icon={faLinkedin} className="social_icon" onClick={() => window.open('https://www.linkedin.com/company/applibitum/')} />
                </SocialIcons>
                </Col>
                <Col md={3} style={{color: '#fff', fontFamily: 'Teko'}}>App Libitum I/S</Col>
                <Col md={3} style={{color: '#fff', fontFamily: 'Teko'}}>CVR: 43171429</Col>
                <Col md={3} style={{color: '#fff', fontFamily: 'Teko'}}>Tlf.: 42700215</Col>
                </Row>
            </FooterMain>
        )
    }
}
export default Footer